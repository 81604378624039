export const CONTRACT_GOERLI = '0x105088644A2CFebDAEeE95fb3F4f29abF75f700E';
export const MC = '0x00000000002Fd5Aeb385D324B580FCa7c83823A0';
export const MC_ABI = [
  { inputs: [], stateMutability: 'payable', type: 'constructor' },
  { inputs: [], name: 'ArrayLengthsMismatch', type: 'error' },
  { inputs: [], name: 'Reentrancy', type: 'error' },
  {
    inputs: [
      { internalType: 'address[]', name: 'targets', type: 'address[]' },
      { internalType: 'bytes[]', name: 'data', type: 'bytes[]' },
      { internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
    ],
    name: 'aggregateWithSender',
    outputs: [{ internalType: 'bytes[]', name: '', type: 'bytes[]' }],
    stateMutability: 'payable',
    type: 'function',
  },
  { stateMutability: 'payable', type: 'receive' },
];
export const ABI = [
  { inputs: [], name: 'LENGTHS_MISMATCH', type: 'error' },
  { inputs: [], name: 'NOT_OWNER', type: 'error' },
  { inputs: [], name: 'ONLY_INITIATOR', type: 'error' },
  { inputs: [], name: 'PAY_FEE', type: 'error' },
  { inputs: [], name: 'ReentrancyGuardReentrantCall', type: 'error' },
  { inputs: [], name: 'TRADE_CLOSED', type: 'error' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'uint256', name: 'tradeId', type: 'uint256' }],
    name: 'TradeAccepted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'uint256', name: 'tradeId', type: 'uint256' }],
    name: 'TradeCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'tradeId', type: 'uint256' },
      { indexed: true, internalType: 'address[]', name: 'nfts', type: 'address[]' },
      { indexed: true, internalType: 'address[]', name: 'requestNfts', type: 'address[]' },
    ],
    name: 'TradeOpened',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'tradeId', type: 'uint256' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
    ],
    name: 'acceptTrade',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tradeId', type: 'uint256' }],
    name: 'cancelTrade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'fee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tradeId', type: 'uint256' }],
    name: 'getOffers',
    outputs: [
      { internalType: 'address[]', name: '', type: 'address[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'address[]', name: '', type: 'address[]' },
      { internalType: 'bool', name: '', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'offerNfts', type: 'address[]' },
      { internalType: 'uint256[]', name: 'offerNftIds', type: 'uint256[]' },
      { internalType: 'address[]', name: 'requestNfts', type: 'address[]' },
    ],
    name: 'openTrade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'uint256', name: '_fee', type: 'uint256' }],
    name: 'setFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'trades',
    outputs: [
      { internalType: 'address', name: 'initiator', type: 'address' },
      { internalType: 'bool', name: 'isOpen', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'withdrawFees', outputs: [], stateMutability: 'nonpayable', type: 'function' },
];
