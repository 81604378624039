import React from 'react';
import { VStack, Divider, Text, Flex, Box } from '@chakra-ui/react';
import { CONTRACT_GOERLI, ABI } from '../data/abi.js';
import Search from './Search';
import Dashboard from './Dashboard';

const Hero = () => {
  return (
    <Flex direction="column" alignItems="center" justifyContent="center" minH="72vh">
      <VStack spacing={9}>
        <Box>
          <Flex justifyContent="center" alignItems="center">
            <Text
              fontSize={['3xl', '5xl', '7xl']}
              fontWeight="bold"
              letterSpacing="-0.02em"
              textShadow="sm"
              lineHeight={['initial', '3rem', '5rem']}
              /* my="1px" */
              as="h1"
              /* bgGradient="linear(to-tr, #999999, #808080, #696969, #505050, #383838)" */
              bgGradient="linear(to-tr, #999999, #808080, #696969, #505050, #383838, #202020, #000000)"
              /* bgGradient="linear(to-tr, #000000, #505050, #383838)" */
              bgClip="text"
            >
              Oraclescan
            </Text>
          </Flex>
          {/*           <Text
            textAlign="center"
            fontSize={['md', 'xl', '4xl']}
            fontWeight="bold"
            letterSpacing="-0.02em"
            textShadow="sm"
            lineHeight={['initial', '1rem', '4rem']}
            justifyContent="center"
            as="h2"
          >
            like never before
          </Text> */}
        </Box>
        <VStack spacing={3} p={1} /* mt="9"  */ borderRadius="lg" boxShadow="lg" maxWidth="600px" w="100%">
          {/* <Text as="h3" fontSize={['xl', '2xl', '3xl']} fontWeight="bold">Offers Feed</Text> */}
          {/* <Divider /> */}
          {/*           <Divider /> */}
          {/* <Search />
          <Dashboard /> */}
        </VStack>
      </VStack>
    </Flex>
  );
};

export default Hero;
