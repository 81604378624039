import { Flex, Heading, Button, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      p={3}
      px={[2, 12, 32]}
      position="sticky"
      top="0"
      backgroundColor="rgba(0, 0, 0, 0.6)"
      backdropFilter="blur(4px)"
      zIndex="1"
    >
      <Link to="/">
        <Flex align="center" cursor="pointer" gap={'1px'}>
          {/* <Image src="logo192.png" boxSize="48px" /> */}
          <Heading size="md" fontWeight={'extrabold'}>
            Oraclescan
          </Heading>
        </Flex>
      </Link>
      {/*       <Flex align="flex-end" gap={'12px'}>
        <Link to="/inventory">
          <Button
            fontWeight={'extrabold'}
            backdropFilter="blur(10px)"
            backgroundColor="rgba(24, 24, 24, 0.1)"
            borderRadius={'xl'}
            borderColor="gray.500"
            borderWidth="1px" color="white"  size="md"
          >
            Inventory
          </Button>
        </Link>
      </Flex> */}
    </Flex>
  );
};

export default Header;
